.App {
  font-family: sans-serif;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.App-logo {
  height: 80px;
}

.App-nav {
  float: right;
}

.App-link {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.App-hero {
  background-image: url('logo.svg');
  background-size: cover;
  background-position: center;
  height: 400px;
  text-align: center;
  color: white;
  padding: 20px;
}

.App-hero h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.App-content {
  padding: 20px;
}

.App-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.App-footer {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}
